import type { OverlayDTO } from '~/types/api/bff/web/contentful/component.types'

export enum BroadcastChannelActions {
  INIT_TAB = 'OVERLAY_INIT_TAB',
  SHARE_DATA = 'OVERLAY_SHARE_DATA',
}

export function useSyncSession(
  overlaySessionKey: string,
  broadcastChannelName: string
) {
  const sessionData = useSessionStorage(overlaySessionKey, {})
  const broadcastChannel = new BroadcastChannel(broadcastChannelName)
  broadcastChannel.addEventListener('message', handleMessage)

  function handleMessage(event: MessageEvent) {
    const { action } = event.data
    // shared overlay data across tabs if new tab is opened
    if (action === BroadcastChannelActions.INIT_TAB && sessionData.value) {
      broadcastChannel.postMessage({
        action: BroadcastChannelActions.SHARE_DATA,
        overlay: JSON.stringify(sessionData.value),
      })
    }
    // update synced overlay data
    if (action === BroadcastChannelActions.SHARE_DATA) {
      event.data.overlay &&
        updateSessionWithSyncedData(JSON.parse(event.data.overlay))
    }
  }

  function onOpenTab() {
    broadcastChannel.postMessage({
      action: BroadcastChannelActions.INIT_TAB,
    })
  }

  function updateSessionWithSyncedData(data: Record<string, string>) {
    sessionData.value = { ...sessionData.value, ...data }
  }

  function isKeyIncludedInSessionData(key?: string) {
    return !!key && !!sessionData.value && key in sessionData.value
  }

  function onInitTab(overlay?: OverlayDTO) {
    //Wont since in preview mode so that overlay always displays in preview
    if (overlay && !isPreviewOverlay()) {
      const overlayId = overlay.id
      const overlayData = { [overlayId]: useRoute().path }

      updateSessionWithSyncedData(overlayData)
      // sync data across tabs
      broadcastChannel.postMessage({
        action: BroadcastChannelActions.SHARE_DATA,
        overlay: JSON.stringify(sessionData.value),
      })
    }
  }

  onUnmounted(() => {
    broadcastChannel.removeEventListener('message', handleMessage)
  })

  return {
    sessionData,
    broadcastChannel,
    updateSessionWithSyncedData,
    isKeyIncludedInSessionData,
    onOpenTab,
    onInitTab,
  }
}
