import picomatch from 'picomatch'
import type { OverlayDTO } from '~/types/api/bff/web/contentful/component.types.ts'

export function useMatchingOverlay(overlays: OverlayDTO[]) {
  const matchingOverlay = computed(() => {
    if (isPreviewOverlay()) return overlays?.[0]

    return overlays.find(
      overlay =>
        isIncludedPlatform(overlay.platform) &&
        isOverlayMatched(
          useRoute()?.path.replace('/preview', ''),
          overlay.includedPages,
          overlay.excludedPages
        )
    )
  })

  function isIncludedPlatform(platform: string[]) {
    const { isMobile } = useDevice()
    const currentPlatform = isMobile ? 'mobile_web' : 'web'
    return platform?.includes(currentPlatform)
  }

  function isOverlayMatched(
    path: string = '',
    includedPages: string[],
    excludedPages: string[]
  ) {
    const isIncludedPages = picomatch.isMatch(path, includedPages)
    const isExcludedPages = picomatch.isMatch(path, excludedPages)
    if (!isIncludedPages || isExcludedPages) return false

    return true
  }

  return { matchingOverlay, isOverlayMatched }
}
